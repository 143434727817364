<template>
<div>
 <the-header></the-header>
 <router-view v-slot="slotProps">
   <transition name="route" mode="out-in">
     <component :is="slotProps.Component"></component>
   </transition>
 </router-view>
</div>
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue'
export default {
  components: { TheHeader },
  created() {
    this.$store.dispatch('tryLogin');
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    }
  }, 
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/');
      }
    }
  }
}
</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  min-width: 300px;

  
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}
.route-leave-to {
  opacity: 0;
  transform: translateY(30px)

}
.route-enter-active {
  transition: all 0.3s ease-out;

}

.route-leave-active {
  transition: all 0.3s ease-in;
}
.route-enter-to,
.route-leavve-from {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 530px) {

#app{
  min-width: 330px;
}
html, body {
  overflow-x: hidden;
}
body{
  position: relative;
}
}
@media screen and (max-width: 350px) {

#app{
  min-width: 310px;
}
}

</style>
