<template>
    <div>
        <base-dialog :show="!!error" title="An error occurred" @close="handleError">
            <p>{{ error }}</p>
        </base-dialog>
        <base-dialog :show="isLoading" title="Authenticating..." fixed >
            <base-spinner></base-spinner>
        </base-dialog>
        <base-card>
            <form @submit.prevent="submitForm">
                <h2>{{ submitButtonCaption }}</h2>
                <div class="form-control">
                    <label for="email">E-Mail</label>
                    <input type="email" id="email" v-model.trim="email">
                </div>
                <div class="form-control">
                    <label for="password">Password</label>
                    <input type="password" id="password" v-model.trim="password">
                </div>
                <p v-if="!formIsValid">Please enter a Valid email and password (must be at least 6 characters long).</p>
                <base-button>{{ submitButtonCaption }}</base-button>
                <base-button type="button" mode="flat" @click="switchAuthMode">{{ switchModeButtonCaption }}</base-button>
                <base-button mode="reset" link to='/resetpassword'>Reset Password</base-button>    
               </form>     
       </base-card>
    </div>
</template>

<script>
import BaseButton from '../../components/ui/BaseButton.vue';
import BaseDialog from '../../components/ui/BaseDialog.vue';
import BaseSpinner from '../../components/ui/BaseSpinner.vue';
export default {
  components: { BaseDialog, BaseSpinner, BaseButton },
    data() {
       return {
           email: '',
           password: '',
           formIsValid: true,
           mode: 'login',
           isLoading: false,
           error: null
       } 
    },
    computed: {
        submitButtonCaption() {
            if (this.mode === 'login') {
                return 'Login';
            } else {
                return 'Signup';
            }
        },
        switchModeButtonCaption() {
            if (this.mode === 'login') {
                return 'Signup instead';
            } else {
                return 'Login instead';
            }
        }
    },
    methods: {
       async submitForm() {
            this.formIsValid = true;
            if (this.email === '' || !this.email.includes('@') || this.password.length < 6) {
              this.formIsValid = false;
              return;
            }

            this.isLoading = true;

            const actionPayload = {
                  email: this.email,
                  password: this.password  
            };

            try{
               if(this.mode == 'login') {
               await this.$store.dispatch('login', actionPayload)
            } else {
              await this.$store.dispatch ('signup', actionPayload);
            }
            const redirectUrl = '/' + (this.$route.query.redirect || 'wisdoms')
            this.$router.replace(redirectUrl)
            } catch (err) {
                this.error = err.message || 'Failed to authnticate, try again later'
            }
           
            this.isLoading = false;
        },
        switchAuthMode() {
            if (this.mode === 'login') {
                this.mode = 'signup';
            } else {
                this.mode = 'login'
            }
        },
        handleError() {
            this.error = null;
        }
    }
}
</script>

<style scoped>
    form {
    margin: 1rem;
    padding: 1rem;
    }

    .form-control {
    margin: 0.5rem 0;
    }

    label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
    }

    input,
    textarea {
    display: block;
    width: 100%;
    font: inherit;
    border: 1px solid #ccc;
    padding: 0.15rem;
    }

    input:focus,
    textarea:focus {
    border-color: #3d008d;
    background-color: #faf6ff;
    outline: none;
    }

</style>